import React, { createContext, useState, useEffect } from 'react'

export const LanguageContext = createContext()

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('lt')
  const [altLanguagePath, setAltLanguagePath] = useState(null)
  const [langPath, setLangPath] = useState(language === 'lt' ? '' : '/' + language)

  useEffect(() => {
    const getLanguageFromUrl = () => {
      const path = window.location.pathname.split('/')
      if (path.length > 1 && path[1].length === 2) {
        setLanguage(path[1])
      } else {
        setLanguage('lt')
      }
    }

    getLanguageFromUrl()

    const handleRouteChange = () => {
      getLanguageFromUrl()
    }

    window.addEventListener('popstate', handleRouteChange)

    return () => {
      window.removeEventListener('popstate', handleRouteChange)
    }
  }, [])

  useEffect(() => {
    setLangPath(language === 'lt' ? '' : '/' + language)
  }, [language])

  return (
    <LanguageContext.Provider 
      value={{ language, setLanguage, altLanguagePath, setAltLanguagePath, langPath, setLangPath }}>
      {children}
    </LanguageContext.Provider>
  )
}
