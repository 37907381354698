exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-us-page-js": () => import("./../../../src/templates/aboutUsPage.js" /* webpackChunkName: "component---src-templates-about-us-page-js" */),
  "component---src-templates-b-2-b-page-js": () => import("./../../../src/templates/b2bPage.js" /* webpackChunkName: "component---src-templates-b-2-b-page-js" */),
  "component---src-templates-category-page-js": () => import("./../../../src/templates/categoryPage.js" /* webpackChunkName: "component---src-templates-category-page-js" */),
  "component---src-templates-contacts-page-js": () => import("./../../../src/templates/contactsPage.js" /* webpackChunkName: "component---src-templates-contacts-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/indexPage.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-privacy-policy-page-js": () => import("./../../../src/templates/privacyPolicyPage.js" /* webpackChunkName: "component---src-templates-privacy-policy-page-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/productPage.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-quality-page-js": () => import("./../../../src/templates/qualityPage.js" /* webpackChunkName: "component---src-templates-quality-page-js" */),
  "component---src-templates-where-to-buy-page-js": () => import("./../../../src/templates/whereToBuyPage.js" /* webpackChunkName: "component---src-templates-where-to-buy-page-js" */)
}

